import React, { useState } from 'react';
import './FontPicker.css'
const FontPicker = ({ selectedFont, setSelectedFont, labelText, labelFontSize}) => {
 

  // List of fonts known to be effective for presentations
  const fonts = [
    'Arial',            // Standard and versatile
    'Arial Black',      // Bold and impactful
    'Calibri',          // Modern and clean
    'Cambria',          // Classic and professional
    'Times New Roman',  // Traditional and formal
    'Georgia',          // Elegant and sophisticated
    'Tahoma',           // Clear and readable
    'Verdana',          // Friendly and modern
    'Garamond',         // Classic and elegant
    'Trebuchet MS',     // Clean and stylish
    'Courier New',      // Monospace and formal
    'Comic Sans MS',   // Casual and playful
    'Impact',           // Bold and dramatic
    'Lucida Console',   // Monospace and legible
    'Palatino Linotype', // Elegant and classic
    'Book Antiqua',    // Classic and readable
    'Century Gothic',   // Modern and clean
    'Frank Ruhl',       // Traditional and bold
    'Baskerville',      // Classic and refined
];


  const handleChange = (event) => {
    const font = event.target.value;
    setSelectedFont(font);
    
  };

  return (
    <div className="main-f">
        <div className="container-f">
    <div style={{ fontFamily: selectedFont, marginBottom: '20px' }}>
      <label className='text-f' htmlFor="font-picker" style={{ marginRight: '10px', fontSize: labelFontSize }} >
      {labelText}
      </label>
      <select
      className='font-picker'
        id="font-picker"
        value={selectedFont}
        onChange={handleChange}
        style={{
          fontFamily: selectedFont,
          padding: '10px',
          fontSize: '16px',
         
         
        }}
      >
        {fonts.map((font) => (
          <option key={font} value={font} style={{ fontFamily: font }}>
            {font}
          </option>
        ))}
      </select>
    </div>
    </div>
    </div>
  );
};

export default FontPicker;
