import React from 'react';
import './ColorSchemesLine.css';

const ColorSchemesLine = ({ selectedColorLine, setselectedColorLine }) => {

  const handleColorChangeText = (event) => {
    setselectedColorLine(event.target.value);
  };

  return (
    <div className="radio-input-l">
      <div className="container-l">
        <div>
          <span className='text-colors-btn-t'>Select Line Color</span>
        </div>
        <div>
          <input
            type="radio"
            value="#FFFFFF"
            id="color-1-l"
            name="color"
            checked={selectedColorLine === '#FFFFFF'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-1-l">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#000000"
            id="color-2-l"
            name="color"
            checked={selectedColorLine === '#000000'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-2-l">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF">
                <path fill="#FFFFFF" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#CE3799"
            id="color-3-l"
            name="color"
            checked={selectedColorLine === '#CE3799'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-3-l">
            <span className='icon'>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#009688"
            id="color-4-l"
            name="color"
            checked={selectedColorLine === '#009688'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-4-l">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#FFD700"
            id="color-5-l"
            name="color"
            checked={selectedColorLine === '#FFD700'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-5-l">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#00BFFF"  
            id="color-6-l"
            name="color"
            checked={selectedColorLine === '#00BFFF'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-6-l">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ColorSchemesLine;
