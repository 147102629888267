import React from 'react';
import './ColorSchemes.css';

const ColorSchemes = ({ selectedColor, setSelectedColor }) => {
  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  return (
    <div className="radio-input">
      <div className="container-t">
        <div>
          <span className='text-colors-btn'>Select Slides Color</span>
        </div>
        <div>
          <input
            type="radio"
            value="#56289f"
            id="color-1"
            name="l"
            checked={selectedColor === '#56289f'}
            onChange={handleColorChange}
          />
          <label htmlFor="color-1">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF">
                <path fill="#FFFFFF" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#000000"
            id="color-2"
            name="m"
            checked={selectedColor === '#000000'}
            onChange={handleColorChange}
          />
          <label htmlFor="color-2">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF">
                <path fill="#FFFFFF" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#ffffff"
            id="color-3"
            name="n"
            checked={selectedColor === '#ffffff'}
            onChange={handleColorChange}
          />
          <label htmlFor="color-3">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#003366"
            id="color-4"
            name="o"
            checked={selectedColor === '#003366'}
            onChange={handleColorChange}
          />
          <label htmlFor="color-4">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF">
                <path fill="#FFFFFF" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#ADD8E6"
            id="color-5"
            name="p"
            checked={selectedColor === '#ADD8E6'}
            onChange={handleColorChange}
          />
          <label htmlFor="color-5">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#D3D3D3"
            id="color-6"
            name="q"
            checked={selectedColor === '#D3D3D3'}
            onChange={handleColorChange}
          />
          <label htmlFor="color-6">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          
        </div>
      </div>
    </div>
  );
};

export default ColorSchemes;
