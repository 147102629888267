import React from 'react';
import './ColorSchemesText.css';

const ColorSchemesText = ({ selectedColorText, setSelectedColorText }) => {

  const handleColorChangeText = (event) => {
    setSelectedColorText(event.target.value);
  };

  return (
    <div className="radio-input-t">
      <div className="container-y">
        <div>
          <span className='text-colors-btn-t'>Select Text Color</span>
        </div>
        <div>
          <input
            type="radio"
            value="#FFFFFF"
            id="color-1-t"
            name="a"
            checked={selectedColorText === '#FFFFFF'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-1-t">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#000000"
            id="color-2-t"
            name="b"
            checked={selectedColorText === '#000000'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-2-t">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF">
                <path fill="#FFFFFF" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#FFD700"
            id="color-3-t"
            name="c"
            checked={selectedColorText === '#FFD700'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-3-t">
            <span className='icon'>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#009688"
            id="color-4-t"
            name="d"
            checked={selectedColorText === '#009688'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-4-t">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#E94E77"
            id="color-5-t"
            name="e"
            checked={selectedColorText === '#E94E77'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-5-t">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>

          <input
            type="radio"
            value="#00BFFF"  
            id="color-6-t"
            name="f"
            checked={selectedColorText === '#00BFFF'}
            onChange={handleColorChangeText}
          />
          <label htmlFor="color-6-t">
            <span>
              <svg width="30px" height="30px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                <path fill="#000000" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04-316.8-316.8a64 64 0 0 1 0-90.496z"></path>
              </svg>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ColorSchemesText;
