import React from 'react';
import './Glitch.css';

function Glitch({ size = '32px' }) {
  const style = {
    fontSize: size,
  };

  return (
    <div className="glitch" data-glitch="AI SLIDE STUDIO" style={style}>
      AI SLIDE STUDIO
    </div>
  );
}

export default Glitch;
