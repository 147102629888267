import React from 'react';
import './Pricing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

function Pricing() {
    return (
        <div className="pricing" id="pricing">
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Responsive Pricing Section [Pure CSS]</title>
            {/* Google Fonts Link */}
            <link
                href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap"
                rel="stylesheet"
            />
            <section className='main-p'>
                <div className="container-p">
                    <div className="section-intro">

                        <h2 style={{ textDecoration: 'line-through' }}>SIMPLE PRICING FOR EVERYONE</h2>
                        <span className='free-label-p'>CURRENTLY 100% FREE</span>
                        <div className="section-style" />
                    </div>
                    <div className="pricing-table__wrapper">
                        <div className="pricing-card-ps">
                            <div className="row">
                                <div className="card-p">
                                    <div className="card-p-pricing__header" style={{ textDecoration: 'line-through' }}>
                                        <h4>Free Plan</h4>
                                    
                                        <h1 className="card-p-pricing__price" >
                                            <span  className='sign'>$</span>0.00
                                            
                                        </h1>
                                    </div>
                                    <ul className="card-p-pricing__list">
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Generate unlimited presentations
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Customize with colors, fonts, and designs.
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Easily share your presentations
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> High Quality Editable Format in pptx
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> <span className='bold-list'> 0 Presentation downloads per month</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Ideal for personal, office, and educational use
                                        </li>
                                    </ul>
                                    <div className="card-p-pricing__footer">
                                        <button className="button-p">Select Plan</button>
                                    </div>
                                </div>
                                <div className="card-p">
                                    <div className="card-p-pricing__header" style={{ textDecoration: 'line-through' }}>
                                        <h4>Pay-Per-Download</h4>
                                      
                                        <h1 className="card-p-pricing__price">
                                            <span className='sign' style={{ marginLeft: '35px' }}>$</span>1.99
                                            <span className='payment-text'>/downoad</span>
                                        </h1>
                                    </div>
                                    <ul className="card-p-pricing__list">
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Generate unlimited presentations
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Customize with colors, fonts, and designs.
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Easily share your presentations
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> High Quality Editable Format in pptx
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> <span className='bold-list'>1 presentation download per payment</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Ideal for personal, office, and educational use
                                        </li>
                                    </ul>
                                    <div className="card-p-pricing__footer">
                                        <button className="button-p">Select Plan</button>
                                    </div>
                                </div>
                                <div className="card-p">
                                    <div className="card-p-pricing__header" style={{ textDecoration: 'line-through' }}>
                                        <h4>Pro Plan</h4>
                                     
                                        <h1 className="card-p-pricing__price">
                                        <span className='sign' style={{ marginLeft: '35px' }}>$</span>9.99

                                            <span className='payment-text'>/month</span>
                                        </h1>
                                    </div>
                                    <ul className="card-p-pricing__list">
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Generate unlimited presentations
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Customize with colors, fonts, and designs.
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Easily share your presentations
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> High Quality Editable Format in pptx
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} />  <span className='bold-list'>20 presentation downloads per month</span>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faCheck} style={{ color: "#56289f" }} /> Ideal for personal, office, and educational use
                                        </li>
                                    </ul>
                                    <div className="card-p-pricing__footer">
                                        <button className="button-p">Select Plan</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Pricing;
