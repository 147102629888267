import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Main.css';
import Greeting from './../../components/Loading';
import ColorScheme from './../../components/ColorSchemes/ColorSchemes'
import img1 from './../../images/13.jpg';

import pptxgen from 'pptxgenjs';
import { motion } from 'framer-motion';
import ColorSchemesText from './../../components/ColorSchemes/ColorSchemesText/ColorSchemesText';
import FontPicker from './../../components/fontPicker/FontPicker';
import Alert from '../Alert/Alert';
import ColorSchemes from './../../components/ColorSchemes/ColorSchemes';
import ColorSchemesLine from '../ColorSchemes/ColorSchemesText/ColorSchemesLine';




function Main() {
  const loadingRef = useRef(null);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [photos, setPhotos] = useState([]);
  const [selectedColor, setSelectedColor] = useState('#000000'); 
  const [selectedLine, setSelectedLine] = useState('#FFD700');
  const [ selectedTitleColor,  setSelectedTitleColor] = useState('#FFFFFF');
 
  const [font1, setFont1] = useState('Arial Black');
  const [font2, setFont2] = useState('Verdana');
  const [font3, setFont3] = useState('Georgia');
    const [showAlert, setShowAlert] = useState([]);
  const alerts = [
    { type: 'info', message: `Note: "AI-Generated Content" Presentations are created by using AI. While the AI strives for accuracy, it may sometimes generate incorrect, misleading, or biased information. Please verify the content independently.` },
    { 
      type: 'success', 
      message: 'Your presentation has been successfully created! You can now customize and download it.' 
  }
  ];
  useEffect(() => {
    if (answer === 'loading...' && loadingRef.current) {
      loadingRef.current.scrollIntoView({ behavior: 'smooth' });
      window.scrollBy(0, 720);
    }
  }, [answer]);



  async function generate() {

    setSelectedColor('#000000');
    setSelectedLine('#FFD700');
    setSelectedTitleColor('#FFFFFF');
    setFont1('Arial Black');
    setFont2('Verdana');
    setFont3('Georgia');
    setShowAlert([]);
    if (!question.trim()) {
      setShowAlert([{ type: 'error', message: 'Error generating slides. Please ensure your input is correct and not empty.' }]);
     
 }
 
    setAnswer('loading...');
   
    try {
      const questionWithSlides = `give me 10 slides content for the topic ${question} with each slide one title, one paragraph of max 110 words (**Paragraph:**) , 3 bullet points of max 30 words (**Bullet Points:**)`;
  
      // Construct the API URL with your API key using template literals
      const apiUrl = `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=${process.env.REACT_APP_GEMINI_API_KEY}`;
  
      // Fetch slide content
      const response = await axios.post(
        apiUrl,
        {
          contents: [{ parts: [{ text: questionWithSlides }] }],
        }
      );

      const content = response.data.candidates[0].content.parts[0].text;
     
      setAnswer(content);
      
       

     axios.get('https://aislidestudio.onrender.com/unsplash-proxy', {
        params: {
          query:` ${question}`,
          per_page: 10,
        },
      })
      .then(response => {
      
        setPhotos(response.data.results);
      
      })
      .catch(error => {
        console.error('Error fetching data from unsplash via proxy:', error);
        setShowAlert([{ type: 'error', message: 'Error generating slides. Please ensure your input is correct and not empty.' }]);
        // Handle error
      });
      

    } catch (error) {
      console.error('Error fetching data:', error);
      setAnswer('Error fetching data. Please try again.');
      setShowAlert([{ type: 'error', message: 'Error generating slides. Please ensure your input is correct and not empty.' }]);
      
    }
  }
 
  const parseSlides = (text, photos) => {
    
    // Split the text by slide markers and remove the first empty element (if any)
    
    const slides = text.split(/\*\*Slide \d+: /).slice(1);
  
    
    return slides.map((slide, index) => {
      
      // Split slide content by lines and filter out empty lines
      const [title, ...rest] = slide.split('\n').filter(line => line.trim());
  
      // Find indices for paragraph and bullet points markers
      const paragraphIndex = rest.findIndex(line => line.startsWith('**Paragraph:**'));
      const bulletPointsIndex = rest.findIndex(line => line.startsWith('**Bullet Points:**'));
  
      // Extract paragraph text
      let paragraph = '';
      if (paragraphIndex !== -1) {
        // Check if paragraph text is on the same line as the marker
        if (rest[paragraphIndex].length > '**Paragraph:**'.length) {
          paragraph = rest[paragraphIndex].substring('**Paragraph:**'.length).trim();
        }
  
        // Gather text from following lines if not on the same line
        for (let i = paragraphIndex + 1; i < rest.length; i++) {
          if (i === bulletPointsIndex || rest[i].startsWith('**')) {
            break; // Stop if another marker is found
          }
          paragraph += (paragraph ? ' ' : '') + rest[i].trim();
        }
      }
  
      // Extract bullet points text
      const bulletPoints = bulletPointsIndex !== -1
        ? rest.slice(bulletPointsIndex + 1).filter(line => !line.startsWith('**')).map(line => line.replace('*', '').trim())
        : [];
        
       
      // Check if bullet points are empty and trigger alert
  
    
  
      // Return the slide object
      return {
        title: title.replace(/\*\*/g, ''),
        paragraph,
        image: photos[index]?.urls?.regular,
        imageAttribution: photos[index]?.user?.name,
        bulletPoints,
      };
    });
  };
  
  function generatePPTX(slides) {
    const pptx = new pptxgen();

  
    slides.forEach((slide, index) => {
      const { title, paragraph, image, bulletPoints,imageAttribution  } = slide;
      
  
      const slideInstance = pptx.addSlide();
      slideInstance.background = { fill: selectedColor };
      
  
      // Add title
      slideInstance.addText(title, { 
        x: 1, 
        y: 0.7, 
        fontSize: 25, 
        bold: true, 
        fontFace: font1, 
        align: 'center',
        color: selectedTitleColor
      });

  
      // Add image
      slideInstance.addImage({ 
        path: image, 
        x: 0.5, 
        y: 1.2, // Adjusted y position for image
        w: 5, 
        h: 3 
      });
      if (imageAttribution) {
        slideInstance.addText(`Photo by ${imageAttribution} on Unsplash`, { 
          x: 0.5, 
          y: 4.3, // Position below image
          fontSize: 10, 
          fontFace: font2, 
          align: 'center',
          color: selectedTitleColor
        });
      }
      // Add paragraph
      slideInstance.addText(paragraph, { 
        x: 0.4, 
        y: 5, // Adjusted y position for paragraph
        w: 9, 
        fontSize:11, 
        fontFace: font2, 
        align: 'left',
        color: selectedTitleColor
      });
        // Add horizontal line above paragraph
    slideInstance.addShape(pptx.ShapeType.rect, {
      x: 0.4,
      y: 4.5, // Adjusted y position for the line
      w: 9.2,
      h: 0.08, // Height of the line
      align: 'center',
      fill: { color: selectedLine }
    });
  
      // Add bullet points
      bulletPoints.forEach((point, i) => {
        const gap = 0.4; // Adjust this value as needed for the desired gap between bullet points
        slideInstance.addText(`• ${point}`, { 
          x: 5.5, 
          y: 1.8 + i * (0.5 + gap), // Adjusted y position for each bullet point with a gap
          w: 4, 
          fontSize: 15, 
          fontFace: font3, 
          italic: true, 
          color: selectedTitleColor,
          margin: 30
        });
      });
    });

 
  
    pptx.writeFile({ fileName: 'GeneratedSlides' });
  }
  
  return (
  <div>
      <div className="hero" id="home">
        
          <motion.div
            initial={{ opacity: 0, y: -10 }}  // Initial animation properties
            whileInView={{ opacity: 1, y: 0 }}    // Animation properties after component mounts
            transition={{ duration: 2 }}        // Transition configuration
            className="headline-container"
          >
            <span className='headline-text'>
              Create AI-Powered Presentations in Seconds
              </span>
          </motion.div>
     
        <div className="hero-content">
          <div className="hero-img-container">
            <motion.img
              initial={{ opacity: 0, x: -200 }}  // Initial animation properties
              whileInView={{ opacity: 1, x: 0 }}    // Animation properties after component mounts
              transition={{ duration: 2 }}
              src={img1}
              alt="Hero"
              className="hero-img"
            />
          </div>
          <div className="hero-form">
            <div className="max-topic">
            <span className="max">(Topic: Max 50 words)</span>
            </div>
            <div className="container-s">
              <div className="search-container">
                <input
                  className="input"
                  type="text"
                  value={question}
                  onChange={(e) => {
                    // Restrict input to 50 characters
                    const inputText = e.target.value;
                    if (inputText.length <= 50) {
                      setQuestion(inputText);
                    }
                  }}
                  placeholder="Enter your topic here"
                />
                <svg viewBox="0 0 24 24" className="search__icon">
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <button className="btn3" onClick={generate}>
              <span className="btn-text">Create Presentation!</span>
              <svg
                fill="#ffffff"
                width="40px"
                height="40px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
              >
                <path d="M12.52.55l-5,5h0L.55,12.51l3,3,12-12Zm-4,6,4-4,1,1-4,4.05ZM2.77,3.18A3.85,3.85,0,0,1,5.32,5.73h0A3.85,3.85,0,0,1,7.87,3.18h0A3.82,3.82,0,0,1,5.32.64h0A3.82,3.82,0,0,1,2.77,3.18ZM8.5,2.55h0A2,2,0,0,1,9.78,1.27h0A1.92,1.92,0,0,1,8.5,0h0A1.88,1.88,0,0,1,7.23,1.27h0A1.92,1.92,0,0,1,8.5,2.55Zm-6.36,0h0A1.92,1.92,0,0,1,3.41,1.27h0A1.88,1.88,0,0,1,2.14,0h0A1.92,1.92,0,0,1,.86,1.27h0A2,2,0,0,1,2.14,2.55ZM14.73,6.22h0a1.94,1.94,0,0,1-1.28,1.27h0a1.94,1.94,0,0,1,1.28,1.27h0A1.9,1.9,0,0,1,16,7.49h0A1.9,1.9,0,0,1,14.73,6.22Z" />
              </svg>
            </button>
         </div>
          <div className="hero-right-text">
              
          {alerts.slice(0, 1).map((alert, index) => (
        <Alert
          key={index}
          type={alert.type}
          message={alert.message}
        />
      ))}
              
         </div>
          
        </div>
      </div>

      {answer === 'loading...' ? (
        <div className="loading-animation" ref={loadingRef}>
          <Greeting />
        </div>
      ) : (
        answer && (
          <>
            <div className="outside">
            <div className='alert-container'>
  {showAlert.length > 0 ? (
    showAlert.map((alert, index) => (
      <Alert
        key={index}
        type={alert.type}
        message={alert.message}
      
      />
    ))
  ) : (
    alerts.slice(1, 2).map((alert, index) => (
      <Alert
        key={index}
        type={alert.type}
        message={alert.message}
      />
    ))
  )}
</div>
              <div className="customize">
                <ColorScheme selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
                <ColorSchemesLine selectedColorLine={selectedLine} setselectedColorLine={setSelectedLine}/>
                
                <ColorSchemesText selectedColorText={selectedTitleColor} setSelectedColorText={setSelectedTitleColor} />
              </div>
              <div className="customize-2">
                <FontPicker selectedFont={font1} setSelectedFont={setFont1}labelText="Select Title Font:"  />
                <FontPicker selectedFont={font2} setSelectedFont={setFont2} labelText="Select BulletPoints Font:" labelFontSize="19px"/>
                <FontPicker selectedFont={font3} setSelectedFont={setFont3} labelText="Select Paragraph Font:" labelFontSize="19px" />
              </div>
              
              {parseSlides(answer, photos).map((slide, index) => (
                
                <div key={index} className="slides-container">
                  
                  <div className="slide" style={{ backgroundColor: selectedColor, color: selectedTitleColor}}>
                    <span  className='slide-title' style={{ fontFamily: font1 }}>{`${slide.title}`}</span>
                    
                    <p style={{ fontFamily: font3 }} className="paragraph">{slide.paragraph}</p>
                    
                    
                      <img src={slide.image} alt={`Server is busy...Please wait for the pictures to load...or there might be no photos available for this topic.`} className="images" />
                      {slide.imageAttribution && (
          <p className="attribution" style={{ color: selectedTitleColor}}>Photo by {slide.imageAttribution} on Unsplash</p>
        )}
                      
                     
                      <ul className="bullet-points" style={{ fontFamily: font2 }}>
                        {slide.bulletPoints.map((point, i) => (
                          <li key={i}>{point}</li>
                        ))}
                      </ul>
                    
                    <div className="horizontal-line"   style={{ backgroundColor: selectedLine}}></div>
                   
                  </div>
                </div>
              ))}
            </div>
          

            
            <div className="downloadbtn" >
              <button className="btn3" onClick={() => generatePPTX(parseSlides(answer, photos))} >
              <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="File / Download_Package"> <path id="Vector" d="M4 8V16.8C4 17.9201 4 18.4798 4.21799 18.9076C4.40973 19.2839 4.71547 19.5905 5.0918 19.7822C5.5192 20 6.07899 20 7.19691 20H16.8031C17.921 20 18.48 20 18.9074 19.7822C19.2837 19.5905 19.5905 19.2839 19.7822 18.9076C20 18.4802 20 17.921 20 16.8031V8M4 8H20M4 8L5.36518 5.61089C5.7002 5.0246 5.86768 4.73151 6.10325 4.51807C6.31184 4.32907 6.55859 4.18605 6.82617 4.09871C7.12861 4 7.46623 4 8.14258 4H15.8571C16.5334 4 16.8723 4 17.1747 4.09871C17.4423 4.18605 17.6879 4.32907 17.8965 4.51807C18.1322 4.73168 18.3002 5.02507 18.6357 5.6123L20 8M12 11V17M12 17L15 15M12 17L9 15" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                DOWNLOAD NOW
              </button>
            </div>
          </>
        )
      )}
    </div>
  );
}

export default Main;